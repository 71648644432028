<template>
  <!-- 挂牌管理 -->
  <div class="hangOutManagementPageBox">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table :operation-button="operationButton" :operation-width="100" :item-data="itemData" :list-data="listData" :loading="loading" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '../../components/FormSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination2'
import { timestampToTime } from '../../utils/util'
import { ordersList } from '@/api/hangOutManagement.js'
import { warehouseList } from '../../api/warehousingSystem'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      itemData: [
        { label: '挂单号', prop: 'orderNo', width: 200, path: '/hangOutManagement/hangOutDetails', pathParameter: 'id' },
        { label: '品名', prop: 'goodsName', width: 80 },
        { label: '规格', prop: 'specs', width: 180 },
        { label: '材质', prop: 'materials', width: 180 },
        { label: '厂家', prop: 'cmpName', width: 180 },
        { label: '仓库', prop: 'warshouse', width: 120 },
        { label: '交收地', prop: 'csgPlace', width: 180 },
        { label: '价格', prop: 'orderPrice', width: 80, type: 'money' },
        { label: '件重(吨)', prop: 'weights', width: 80 },
        // { label: '挂牌数量', prop: 'orderWeight', width: 80 },
        // { label: '剩余数量', prop: 'orderWeightLeft', width: 80 },
        { label: '状态', prop: 'state', width: 80, child: [{ value: '01', label: '已上架' }, { value: '02', label: '已下架' }, { value: '10', label: '全部成交' }] }
      ],
      formItemArr: [
        { type: 'input', label: '挂单号', value: 'orderNo' },
        { type: 'input', label: '品名', value: 'goodsName' },
        { type: 'input', label: '仓库名称', value: 'warshouse' }
      ],
      loading: false,
      total: 0,
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails }
      ],
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        goodsName: '',
        orderNo: '',
        warshouse: ''
      }
    }
  },
  mounted() {
    warehouseList('', res => {
      this.formItemArr.forEach((item) => {
        if (item.value === 'warshouse') item.child = [...res.data]
      })
    })
  },
  methods: {
    viewDetails(row) {
      this.$router.push({ path: '/hangOutManagement/hangOutDetails', query: { id: row.id }})
    },
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      ordersList(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.outDate = timestampToTime(item.outDate)
        })
        this.total = res.data.total
      })
    }
  }
}
</script>
